:root {
  --amplify-primary-color: #9c27b0;
  --amplify-primary-tint: #ab81b3;
  --amplify-primary-shade: #9c27b0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.logo {
  width: 50px;
  padding-right: 10px;
}

.float {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  z-index: 100;
  position: relative;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: center;
}

.box {
  color: #ffffff;
  border-radius: 5%;
}

.mapcontrol {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mapInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 100%;
}